<i18n>
{
  "de": {
    "pageTitle": "Zugangsrechte verwalten",
    "deleteUserPendingMessage": "Benutzerzugang wird entfernt...",
    "noUsers": "Keine Benutzer-/innen",
    "addToUser": "Benutzer/-in hinzufügen",
    "deleteButtonLabel": "Zugriff entziehen",
    "deleteConfirmation": "Wollen Sie Benutzer/-in \"{name}\" wirklich den Zugriff entziehen?",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <MainLayout :ready="portfolioReady && portfolioUsersReady" class="admin-portfolio-users-page">
    <template #default>
      <p v-if="deleteUserError">{{ deleteUserError }}</p>
      <p v-else-if="deleteUserPending">{{ $t('deleteUserPendingMessage') }}</p>
      <template v-else>
        <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
        <ButtonWrapper>
          <div>
            <router-link
              class="button"
              :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }"
              >{{ $t('returnButton') }}</router-link
            >
          </div>
        </ButtonWrapper>
        <br />
        <ul v-if="hasUsers" class="users-list" :class="usersListClasses">
          <PortfolioUserRow v-for="user in users" :key="user.id" class="user-list-row" :user="user">
            <a href="#" @click.prevent="onRemoveUser(user)"
              ><img src="/icons/trash-2.svg" :title="$t('deleteButtonLabel')"
            /></a>
          </PortfolioUserRow>
        </ul>
        <p v-else>{{ $t('noUsers') }}</p>
        <ButtonWrapper>
          <div>
            <router-link
              class="button"
              :to="{ name: 'adminAddUserToPortfolio', params: { portfolio_id: portfolio.id } }"
              >{{ $t('addToUser') }}</router-link
            >
          </div>
        </ButtonWrapper>
      </template>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import PortfolioUsersMixin from '@/pages/vuex-mixins/PortfolioUsersMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import PortfolioUserRow from '@/components/admin-portfolios/PortfolioUserRow.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'adminPortfolioUsers',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioUsersMixin, // Provides: portfolio.users, portfolioUsersLoading, portfolioUsersReady
  ],

  components: {
    MainLayout,
    ButtonWrapper,
    PortfolioUserRow,
    PageTitle,
  },

  data() {
    return {
      deleteUserError: null,
      deleteUserPending: false,
    }
  },

  computed: {
    users() {
      return this.portfolio && this.portfolio.users
    },

    hasUsers() {
      return Boolean(this.users && this.users.length)
    },

    hasAdmins() {
      return Boolean((this.users || []).find((u) => u.admin))
    },

    usersListClasses() {
      return {
        'with-admin-column': this.hasAdmins,
      }
    },
  },

  watch: {
    portfolio(val, oldVal) {
      const { name } = val || {}
      this.name = name
    },
  },

  methods: {
    ...mapActions({
      loadUsersById: 'portfolio/loadUsersById',
      removePortfolioUser: 'portfolio/removePortfolioUser',
    }),

    async onRemoveUser(user) {
      const confirmationText = this.$t('deleteConfirmation', { name: `${user.first_name} ${user.last_name}` })
      if (confirm(confirmationText)) {
        try {
          this.deleteUserPending = true
          this.deleteUserError = null
          await this.removePortfolioUser({ portfolioId: this.portfolio.id, userId: user.id })
        } catch (error) {
          this.deleteUserError = error
        }
        this.deleteUserPending = false
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.admin-portfolio-users-page .users-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: var(--spacing-l);
  row-gap: var(--spacing-s);
  justify-content: start;
  justify-items: start;
  align-items: baseline;
}

.admin-portfolio-users-page .users-list.with-admin-column {
  grid-template-columns: repeat(5, auto);
}

.admin-portfolio-users-page .users-list .portfolio-user-row {
  display: contents;
}
</style>
